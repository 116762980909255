
























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import StickyBar from '@/components/app/StickyBar.vue';

import AuthService from '@/services/auth';

@Component({
  components: {
    StickyBar,
  },
})
export default class BaseAppBar extends Vue {

  canManageUsers: boolean = false;

  getContactPhone() {
    return process.env.VUE_APP_CONTACT_PHONE;
  }

  getContactEmail() {
    return process.env.VUE_APP_CONTACT_EMAIL;
  }

  redirect(route: string) {
    // TODO: Check for redundancy ie. absorb if you are already on the page they are clicking to
    this.$router.push(route);
  }

  async beforeMount() {
    await AuthService.getProfile();
    if (this.$store.getters['users/loggedInUser'].profile) {
      this.canManageUsers = this.$store.getters['users/loggedInUser'].profile.registrationTypeId === 2 // 2: Company
        && this.$store.getters['users/loggedInUser'].profile.userTypeId === 1; // 1: Administrator
    }
  }

}
