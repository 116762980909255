




















// --- Vue & Template imports ---
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

// --- Third Party ---
import gsap from 'gsap';

@Component
export default class StickyBar extends Vue {
  debounceTimeout: number = 0;
  actualElement: HTMLDivElement | null = null;
  offsetTop: number = 0;
  fixedState: boolean = false;
  placeholderElement: HTMLDivElement | null = null;
  timeline: any = false;

  mounted() {
    this.actualElement = document.querySelector('#stickybar');
    this.placeholderElement = document.querySelector('#stickybarPlaceholder');

    if (this.actualElement) {
      // Set the offset that this element is at when it is mounted
      this.offsetTop = this.actualElement.offsetTop;

      this.$nextTick(() => {
        if (this.placeholderElement) {
          gsap.set(this.placeholderElement, { duration: 0, height: this.actualElement ? this.actualElement.clientHeight : 'auto'});
        }
      });

      this.updateState();
    }
  }

  updateState() {
    const bodyEl: HTMLBodyElement = document.getElementsByTagName('body')[0];
    if (
      window.scrollY > this.offsetTop || 
      ((bodyEl.style.position === 'fixed') && Math.abs(bodyEl.getClientRects()[0].top) > this.offsetTop )) {
      gsap.set(this.actualElement, { 
        duration: 0,
        position: 'fixed',
        top: 0,
      });
    } else {
      gsap.set(this.actualElement, { 
        duration: 0,
        position: 'absolute',
        top: this.offsetTop,
      });
    }
  }

  resizePlaceholder() {
    this.actualElement = document.querySelector('#stickybar');
    if (this.placeholderElement) {
      gsap.set(this.placeholderElement, { duration: 0, height: this.actualElement ? this.actualElement.clientHeight : 'auto'});
    }

  }

  // handle scrolling event
  scrolling(event: Event) {
      this.updateState();
  }

  windowPosition() {
    return window.scrollY;
  }

  currentPosition() {
    return this.$el ? this.$el.getBoundingClientRect() : [];
  }
  // #endregion

  // * manually emit & destroy events * //
  /*
    emitter.$on(component.handleClick)
    component.$on("$destroy", () => emitter.$off('click', component.handleClick))

  */
}
